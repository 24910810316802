/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react"
import { Box, Text } from "grommet"
import { mainBlue, mainWhite } from "../../styles/constants"
import styled from "styled-components"
import { Button } from "../../styles/components/button"

const CookiesWrapper = styled(Box)`
  position: fixed;
  bottom: 0;
  right: 0;
  height: auto;
  padding: 12px;
  width: 100%;
  justify-content: center;
  flex-flow: column wrap;
  align-items: center;
  z-index: 9;
  background-color: ${mainBlue};
`

const setCookie = (cname: string, cvalue: string, exdays: number): void => {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = `expires=` + d.toUTCString()
  if (typeof document !== `undefined`) {
    document.cookie = cname + `=` + cvalue + `;` + expires + `;path=/`
  }
}

const getCookie = (cname: string): string => {
  if (typeof document !== `undefined`) {
    const name = cname + `=`
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(`;`)
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == ` `) {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
  }
  return ``
}

const Cookies: React.FunctionComponent = () => {
  const cookie: string = getCookie(`cookies`)
  const [show, setShow] = useState<boolean>(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (cookie === `submitted`) {
        setShow(false)
      } else {
        setShow(true)
      }
    }, 3000)

    return (): void => clearTimeout(timer)
  }, [cookie])

  if (!show) {
    return null
  }

  return (
    <CookiesWrapper>
      <Text color={mainWhite} size="small">
        Táto web stránka používa súbory cookies. Pokračovaním prehliadania tejto web stránky vyjadrujete súhlas s ich
        využívaním. Informácie o využívaní{` `}
        <a href="/pouzivanie-suborov-cookies" className="underlined" target="_blank">
          {` `}
          súborov cookies a spracúvaní osobných údajov.
        </a>
      </Text>
      <Button
        onClick={(): void => {
          setCookie(`cookies`, `submitted`, 30)
          setShow(false)
        }}
        mt={8}
        mb={0}
        pad="4px 12px"
        bg={mainWhite}
        color={mainBlue}
      >
        Súhlasím
      </Button>
    </CookiesWrapper>
  )
}

export default Cookies
