import styled, { css } from "styled-components"
import { mainWhite, mainBlue } from "../constants"

interface ButtonProps {
  mt?: number
  mb?: number
  mr?: number
  ml?: number
  bg?: string
  color?: string
  size?: string
  disabled?: boolean
  pad?: string
}

export const Button = styled.button`
  display: inline-block;
  cursor: pointer;
  font: inherit;
  text-decoration: none;
  margin: 0;
  background: transparent;
  overflow: visible;
  text-transform: none;
  margin-left: 0;
  margin-right: 0;
  margin-top: ${(props: ButtonProps): number => props.mt || 0}px;
  margin-bottom: ${(props: ButtonProps): number => props.mb || 0}px;
  margin-left: ${(props: ButtonProps): number => props.ml || 0}px;
  margin-right: ${(props: ButtonProps): number => props.mr || 0}px;
  border-radius: 18px;
  padding: ${(props: ButtonProps): string => props.pad || `12px 32px`};
  font-size: 18px;
  line-height: 24px;
  pointer-events: ${(props: ButtonProps): string => (props.disabled ? `none` : `initial`)};
  opacity: ${(props: ButtonProps): string => (props.disabled ? `0.3` : `1`)};
  color: ${(props: ButtonProps): string => props.color || mainWhite};
  background-color: ${(props: ButtonProps): string => props.bg || mainBlue};
  border-radius: 18px;

  ${(props: ButtonProps): any =>
    props.size === `small` &&
    css`
      padding: 12px 20px;
    `}
`
