import { createGlobalStyle, keyframes } from "styled-components"
import { mainBlack, mainBlue, mainGrey, mainWhite } from "../constants"

const pulse = keyframes`
  0% { transform: scale(1) }
  50% { transform: scale(1.1) }
  100% { transform: scale(1) }
`

export const GlobalStyle = createGlobalStyle`
  a {
    color: ${mainWhite};
    text-decoration: none;
  }

  html {
    height: initial;
  }

  body {
    height: 100%;
    -webkit-overflow-scrolling: touch;
  }

  button {
    border: none;
    &:focus {
      border: none;
      outline: none;
    }
  }

  .notification {
    box-shadow: none !important;
  }

  input {
    border-radius: 0;
  }

  .bg-grey {
    background: ${mainGrey} !important
  }

  .header {
    background-color: ${mainBlue};
  }

  .logo {
    transition: all linear .35s;
    &:hover {
      transition: all linear .35s;
      opacity: 0.65;
      transform: scale(1.02);
    }
  }

  .tl-wrapper {
    position: inherit !important;
  }

  .underlined {
    text-decoration: underline;
    font-weight: bold;
  }


  .mobile-logo-menu {
    position: relative;
    right: 18px;
  }

  .black {
    color: ${mainBlack}
  }

  .contact-areas {
    grid-template-areas:
      "name phone"
      "mail mail"
      "message message"
      "approve button";
  }

  .contact-areas-mobile {
    grid-template-areas:
      "name phone"
      "mail mail"
      "message message"
      "approve approve"
      "button .";
  }

  .scrollArea {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .layout {
    height: 100vh;
    grid-template-areas: ". layout .";
  }

  .relative {
    position: relative;
  }

  .absolute {
    position: absolute;
  }

  .bottom-0 {
    bottom: 0;
  }

  .fixed {
    position: fixed;
  }

  .cursor {
    cursor: pointer;
  }

  .trails-main {
    position: relative;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }

  .trails-text {
    position: relative;
    width: 100%;
    height: 800;
    line-height: 800;
    color: black;
    font-weight: 800;
    will-change: transform, opacity;
    overflow: hidden;
  }

  .trails-text > div {
    overflow: hidden;
  }

  a.anchor {
    position: relative;
    user-select: none;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background-color: ${mainWhite};
      transition: all linear 0.35s;
    }
    &:hover {
      &:after {
        width: 100%;
        transition: all linear 0.35s;
      }
    }
  }

  a.menu-anchor {
    position: relative;
    user-select: none;
    cursor: pointer;
    color: ${mainBlue};
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background-color: ${mainBlue};
      transition: all linear 0.35s;
    }
    &:hover {
      &:after {
        width: 100%;
        transition: all linear 0.35s;
      }
    }
  }

  .border-action {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: -18px;
      left: 0;
      width: 0;
      height: 1px;
      width: 100%;
      opacity: 0.2;
      background-color: ${mainBlack};
      transition: all linear 0.35s;
    }
  }

  // loading
  .pulse {
    animation: ${pulse} 1s linear infinite;
  }

  .people {
    img {
      border-radius: 50%;
    }
  }
`
