/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, ReactNode } from "react"
import { Grommet, ThemeType } from "grommet"
import { FormAdd, FormSubtract, CheckboxSelected } from "grommet-icons"
import "normalize.css"

import { mainBlue, mainWhite } from "../../styles/constants"
import ScrollContext from "../../context/scroll-context"
import Cookies from "./cookies"
import { GlobalStyle } from "../../styles/components/global"

const theme: ThemeType = {
  checkBox: {
    check: {
      radius: `0px`,
      thickness: `8px`,
    },
    border: {
      color: mainBlue,
      width: `4px`,
    },
    gap: `12px`,
    icons: {
      checked: (): JSX.Element => <CheckboxSelected color={mainBlue} />,
    },
    hover: {
      border: {
        color: mainBlue,
      },
    },
    toggle: {
      color: mainBlue,
    },
    color: mainBlue,
  },
  accordion: {
    icons: {
      collapse: (): JSX.Element => <FormSubtract color={mainWhite} />,
      color: mainWhite,
      expand: (): JSX.Element => <FormAdd color={mainWhite} />,
    },
    heading: {
      margin: `20px`,
    },
    hover: {
      heading: {
        color: mainWhite,
      },
    },
    panel: {
      border: {
        style: `hidden`,
      },
    },
  },
  global: {
    font: {
      family: `'Red Hat Display', sans-serif`,
    },
    focus: {
      outline: {
        color: `transparent`,
      },
      border: {
        color: `transparent`,
      },
    },
    control: {
      border: {
        radius: `0px`,
      },
    },
  },
  menu: {
    icons: {
      color: mainWhite,
    },
  },
  button: {
    size: {
      medium: {
        pad: {
          vertical: `10px`,
        },
        border: {
          radius: `16px`,
        },
      },
    },
  },
}

const GrommetWrapper: FC = ({ children }) => {
  return (
    <ScrollContext.Consumer>
      {(): JSX.Element => (
        <>
          <GlobalStyle />
          <Grommet theme={theme}>{children}</Grommet>
        </>
      )}
    </ScrollContext.Consumer>
  )
}

export default GrommetWrapper
